<template>
  <div class="table">

    <div class="table-header">
      <span
          class="table-title"
      >{{ configuration.title }}</span>
      <input
          type="text"
          class="table-search-field"
          placeholder="Search"
      />
    </div>

    <table class="table-body">

      <thead>
        <th
            v-for="content, index in configuration.columns"
            :key="index + Math.random()"
        >
          {{ content.title }}
        </th>
      </thead>
      <tbody>
        <tr
            v-for="content, index in tableData"
            :key="index + Math.random()"
        >
          <td
              v-for="column in configuration.columns"
              :key="column + Math.random()"
          >
            {{ content[column] }}
          </td>
        </tr>
      </tbody>

    </table>

    <div class="table-bottom">

    </div>

  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "PaginationTable",
  props: {
    title: String,
    configuration: Object,
    fetchLink: String
  },
  data() {
    return {
      sort: 'desc',
      limit: 5,
      currentPage: 1,
      totalPages: 1,
      tableData: []
    }
  },
  methods: {
    async fetchUsersForTable() {
      const response = await axios.post(this.fetchLink, {
        sort: this.sort,
        limit: this.limit,
        currentPage: this.currentPage
      })
      this.tableData = response.data.tableData;
    }
  },
  async created() {
    await this.fetchUsersForTable();
  }
}
</script>

<style scoped>

.table .table-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.table .table-header .table-title {
  font-size: 21px;
}

.table .table-header .table-search-field {
  outline: none;
  border-width: 0 0 1px 0;
  border-color: #828282;
  background: transparent;
  color: black;
  transition: .3s;
}

.table .table-header .table-search-field:focus {
  border-color: #2A85FF;
}

.table .table-body {
  width: 100%;
}

.table .table-body thead {
  text-align: left;
}

.table .table-body thead th {
  padding: 10px;
}

.table .table-body tbody {
  border-top: 1px solid black;
}

</style>